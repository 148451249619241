import Vue from "vue";
import VueRouter from "vue-router";
import allgoods from "../views/allgoods.vue";
import user from "../views/user.vue";
import pay from "../views/buygoods.vue";
import login from "../views/login.vue";
import wxlogin from "../views/wxlogin.vue";
import test from "../views/57a88b7092a0d3f3af5629753e953abe.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: allgoods,
  },
  {
    path: "/user",
    name: "user",
    component: user,
  },
  {
    path: "/pay",
    name: "pay",
    component: pay,
  },
  {
    path: "/pay-choose-teacher",
    name: "pay-choose-teacher",
    component: () => import("../views/pay-choose-teacher.vue"),
  },
  {
    path: "/no-input-teacher",
    name: "no-input-teacher",
    component: () => import("../views/no-input-teacher.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: login,
  },
  {
    path: "/wxlogin",
    name: "wxlogin",
    component: wxlogin,
  },
  {
    path: "/57a88b7092a0d3f3af5629753e953abe",
    name: "57a88b7092a0d3f3af5629753e953abe",
    component: test,
  },
  {
    path: "/custom-product",
    name: "custom-product",
    component: () => import("../views/custom-product.vue"),
    meta: {
      // hiddenBack: true,
      // hiddenTabbar: true,
      title: "付款"
    }
  },
  {
    path: "*",
    redirect: "/",
    component: allgoods
  }
];

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  routes,
  mode: "history",
});

const whiteList = [
    "/login",
    "/",
    "/wxlogin"
]

router.beforeEach((to, from, next) => {
  let user_id = Vue.prototype.$ls.get("user_id");
  let url =  new URLSearchParams(location.search);
  // 获取地址栏teacher_id
  let teacher_id = url.get("teacher_id");
  let id = url.get("id");
  let query = {};
  if(teacher_id) {
    query["teacher_id"] = teacher_id;
  }
  if(id) {
    query['id'] = id;
  }
  if(user_id) {
    if(to.path === "/login") {
      next({path: "/", query});
    }else {
      next({query});
    }
  }else {
    if(whiteList.includes(to.path)) {
      next({query});
    }else {
      next({path: "/login", query});
    }
  }
})

export default router;
