<script>
const oss = "https://shengjin.oss-cn-hangzhou.aliyuncs.com/weizhen/common/item";
const vip1 = oss + "/vip_seat.jpg" + "?random=" + Date.now();
export default {
  props: ["goodsList", "teacher_id"],
  emits: ['imgClick', 'buyClick'],
  data() {
    return {
      good: null,
      pageHeight: 0,
      pageWidth: 0,
      displayVipImage: vip1,
      resizeBind: this.resize.bind(this),
      ordinary: oss + "/ordinary_seat.jpg" + "?random=" + Date.now()
    }
  },
  watch: {
    goodsList() {
      if (this.goodsList && this.goodsList.length > 0) {
        this.good = this.goodsList[this.goodsList.length - 1];
        this.good.name = "2024上海财富论坛入场券";
      }
    }
  },
  mounted() {
    this.resize();
    window.addEventListener("resize", this.resizeBind);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeBind);
  },
  methods: {
    resize() {
      const tabbarSize = document.querySelector(".van-tabbar").getBoundingClientRect();
      const headerSize = document.querySelector(".van-nav-bar").getBoundingClientRect();
      const pageHeight = window.innerHeight;
      this.pageWidth = window.innerWidth;
      const currentPageHeight = pageHeight - tabbarSize.height - headerSize.height;
      console.log(currentPageHeight);
      this.pageHeight = currentPageHeight;
      // 获取屏幕高度的一半

    },
    imgClick(good) {
      this.$emit("imgClick", good);
    },
    buyClick(good) {
      // this.$emit("buyClick", good);
      switch (good.jump_type) {
        case 1:
          this.$router.push({
            name: "no-input-teacher",
            query: {
              id: good.id,
              teacher_id: this.teacher_id,
            },
          });
          break;
        case 2:
          window.location.href = good.jump_param;
          break;
        default:
          Dialog.alert({
            title: "商品跳转参数异常",
          }).then(() => {
            window.location.href = "/";
          });
          break;
      }
    }
  }
}
</script>

<template>
  <!--  <div class="origin-good-good" v-if="good">-->
  <!--    <van-card-->
  <!--        :desc="good.desc"-->
  <!--        :title="good.name"-->
  <!--        :thumb="good.img_url"-->
  <!--        @click-thumb="imgClick(good)"-->
  <!--    >-->
  <!--      <template #tags>-->
  <!--        <van-tag-->
  <!--            v-show="good.show_inventories == 1"-->
  <!--            plain-->
  <!--            :type="good.inventories_num > 0 ? 'primary' : 'danger'"-->
  <!--        >{{ good.inventories_num > 0 ? "库存数量 : " + good.inventories_num : "已售罄" }}-->
  <!--        </van-tag-->
  <!--        >-->
  <!--      </template>-->
  <!--      <template #origin-price>-->
  <!--        <div v-show="good.original_price != 0">-->
  <!--          原价:{{ teacher_id == 100 ? good.original_price + " / 月" : good.original_price }}-->
  <!--        </div>-->
  <!--      </template>-->
  <!--      <template #price>-->
  <!--        <div v-if="false">-->
  <!--          {{ teacher_id == 100 ? "限时秒杀" : "现价" }}:{{-->
  <!--            teacher_id == 100 ? good.price + " / 天" : good.price-->
  <!--          }}-->
  <!--        </div>-->
  <!--      </template>-->
  <!--      <template #footer>-->
  <!--        <div class="h-1"></div>-->
  <!--        <van-button-->
  <!--            v-for="btn in goodsList"-->
  <!--            :key="btn.id"-->
  <!--            @click="buyClick(btn)"-->
  <!--            :color="btn.inventories_num > 0 ? getBgColor(btn.id) : '#666'"-->
  <!--            round-->
  <!--            :disabled="btn.inventories_num == 0"-->
  <!--        >{{ btn.buy_btn_str }}-->
  <!--        </van-button-->
  <!--        >-->
  <!--      </template>-->
  <!--    </van-card>-->
  <!--  </div>-->
  <div class="page" :style="{height: pageHeight + 'px'}">
    <!--    <img :src="displayVipImage" @click="buyClick(goodsList[0])" :style="{height: pageHeight * 0.5 - 5 + 'px'}">-->
    <!--    <img :src="ordinary" @click="buyClick(goodsList[1])" :style="{height: (pageHeight * 0.5) - 5 + 'px'}">-->
    <p class="mb-3">2024年10月19日入场券已售罄</p>
    <p class="mb-3">感谢您一直关注与支持。</p>
    <p class="mb-3">精彩已满座，期待再相遇！</p>
    <p class="mb-3">如有疑问请点击下方联系客服</p>
    <div class="px-5 w-full">
      <van-button type="primary" block url="tel:13107873675">联系客服</van-button>
    </div>
  </div>
</template>

<style scoped>
img {
  width: 100%;
  object-fit: fill;
}

.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  @apply px-3;
}
</style>